import Photo384 from "../assets/images/Photo384.webp";
import Photo276 from "../assets/images/Photo276.webp";

/**
 * Steps for using different photos depending on the media :
 * 1) Using the https://squoosh.app/ online tool for the 2), 3) and 4) steps ;
 * 2) Resize the original photo to the desired dimensions in the app (to do for each different version of the photo) ;
 * --> The 1.5 ratio seems to be recommended by Lighthouse: 256x256 rendered photo in app = 384x384 source photo dimensions
 * 3) Reduce the weight of all resized photos ;
 * 4) Convert all photos to WebP format ;
 * 5) Define and use an object containing all info about the photo (see below).
 */

type PhotoSrcSet = {
  id: string;
  src: string;
  type: string;
  media: string;
};

const PHOTO = {
  mainSrc: Photo384,
  srcSet: [
    {
      id: "1",
      src: Photo276,
      type: "image/webp",
      media: "(max-width: 768px)",
    },
  ],
  alt: "Damien PROCACCINO",
};

function Photo(): JSX.Element {
  return (
    <div className="rounded-full bg-gradient-to-b from-violet to-blue">
      <picture>
        {PHOTO.srcSet.map((photoSrcSet: PhotoSrcSet) => (
          <source
            key={photoSrcSet.id}
            srcSet={photoSrcSet.src}
            type={photoSrcSet.type}
            media={photoSrcSet.media}
          />
        ))}
        <img
          src={PHOTO.mainSrc}
          alt={PHOTO.alt}
          className="h-48 w-48 md:h-64 md:w-64"
        />
      </picture>
    </div>
  );
}

export default Photo;
