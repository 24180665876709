import "./styles/index.scss";
import { useEffect } from "react";
import useAppStore from "./stores/App";
import { useQueries, UseQueryResult } from "@tanstack/react-query";
import { retrieveAll } from "./services/App";
import { SkillCategory } from "./types/SkillCategory";
import { Skill } from "./types/Skill";
import { Project } from "./types/Project";
import LoadingInProgress from "./components/LoadingInProgress";
import LoadingFailed from "./components/LoadingFailed";
import Header from "./components/Header";
import SectionSkills from "./components/SectionSkills";
import SectionProjects from "./components/SectionProjects";
import Footer from "./components/Footer";

const COLLECTIONS_NAMES = ["skillCategories", "skills", "projects"];

export const SECTIONS = {
  HEADER: {
    id: "intro",
    label: "Intro",
  },
  SKILLS: {
    id: "competences",
    label: "Compétences",
  },
  PERSONAL_PROJECTS: {
    id: "projet-favori",
    label: "Projet favori",
  },
  PROFESSIONAL_PROJECTS: {
    id: "experiences",
    label: "Expériences",
  },
} as const;

function App(): JSX.Element {
  const { setSkillCategories, setSkills } = useAppStore((state) => ({
    setSkillCategories: state.setSkillCategories,
    setSkills: state.setSkills,
  }));

  const allInfos = useQueries({
    queries: COLLECTIONS_NAMES.map((collectionName: string) => ({
      queryKey: [collectionName],
      queryFn: async () => await retrieveAll(collectionName),
    })),
    combine: (
      results: Array<UseQueryResult<Array<SkillCategory | Skill | Project>>>,
    ) => {
      return {
        data: {
          skillCategories: results[0].data as Array<SkillCategory>,
          skills: results[1].data as Array<Skill>,
          projects: results[2].data as Array<Project>,
        },
        isPending: results.some(
          (result: UseQueryResult<Array<SkillCategory | Skill | Project>>) =>
            result.isPending,
        ),
        isError: results.some(
          (result: UseQueryResult<Array<SkillCategory | Skill | Project>>) =>
            result.isError,
        ),
      };
    },
  });

  useEffect(() => {
    if (allInfos.data.skillCategories) {
      setSkillCategories(allInfos.data.skillCategories);
    }

    if (allInfos.data.skills) {
      setSkills(allInfos.data.skills);
    }
  }, [
    allInfos.data.skillCategories,
    allInfos.data.skills,
    setSkillCategories,
    setSkills,
  ]);

  if (allInfos.isPending) {
    return <LoadingInProgress />;
  }

  if (allInfos.isError) {
    return <LoadingFailed />;
  }

  const skillCategoriesToDisplayInSkills = allInfos.data.skillCategories.reduce(
    (acc: Array<SkillCategory>, current: SkillCategory) => {
      const skillsByCategoryId = allInfos.data.skills.filter(
        (skill: Skill) => skill.skillCategoryId === current.skillCategoryId,
      );

      if (
        skillsByCategoryId.some((skill: Skill) => skill.isDisplayedInSkills)
      ) {
        acc.push(current);
      }

      return acc;
    },
    [],
  );

  const personalProjects = allInfos.data.projects.filter(
    (project: Project) => project.personal,
  );

  const professionalProjects = allInfos.data.projects.filter(
    (project: Project) => project.professional,
  );

  const firstProfessionalProjectDateFrom = (): string => {
    const firstProfessionalProject = allInfos.data.projects.find(
      (project: Project) => project.professional && project.num === "1",
    );

    return firstProfessionalProject?.dateFrom || "2016-09";
  };

  const currentDate = (): string => {
    const currentDate = new Date();
    const YYYY = currentDate.getFullYear();
    const MM = String(currentDate.getMonth() + 1).padStart(2, "0");

    return `${YYYY}-${MM}`;
  };

  const experienceYearsNumber = (): number => {
    const [
      firstProfessionalProjectDateFromYYYY,
      firstProfessionalProjectDateFromMM,
    ] = firstProfessionalProjectDateFrom().split("-").map(Number);
    const [currentDateYYYY, currentDateMM] = currentDate()
      .split("-")
      .map(Number);
    const experienceMonths =
      (currentDateYYYY - firstProfessionalProjectDateFromYYYY) * 12 +
      (currentDateMM - firstProfessionalProjectDateFromMM);

    return Math.round(experienceMonths / 12);
  };

  return (
    <div
      className="animate-fadeInUp flex max-w-screen-xl flex-col"
      id={SECTIONS.HEADER.id}
    >
      <Header experienceYearsNumber={experienceYearsNumber()} />
      <SectionSkills
        sectionId={SECTIONS.SKILLS.id}
        sectionLabel={`_ ${SECTIONS.SKILLS.label}`}
        skillCategoriesToDisplayInSkills={skillCategoriesToDisplayInSkills}
      />
      <SectionProjects
        sectionId={SECTIONS.PERSONAL_PROJECTS.id}
        sectionLabel={`${SECTIONS.PERSONAL_PROJECTS.label} _`}
        projects={personalProjects}
        arePersonalProjects={true}
      />
      <SectionProjects
        sectionId={SECTIONS.PROFESSIONAL_PROJECTS.id}
        sectionLabel={`_ ${SECTIONS.PROFESSIONAL_PROJECTS.label}`}
        projects={professionalProjects}
      />
      <Footer sections={Object.values(SECTIONS)} />
    </div>
  );
}

export default App;
