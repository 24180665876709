import { Section } from "../types/Section";
import Logo from "./Logo";
import ContactBar from "./ContactBar";

const CREATION_YEAR = 2024;
const CURRENT_YEAR = new Date().getFullYear();

const COPYRIGHT = [
  {
    id: "1",
    string: `© ${CREATION_YEAR} ${CREATION_YEAR === CURRENT_YEAR ? "" : ` - ${CURRENT_YEAR}`} Damien PROCACCINO`,
  },
  {
    id: "2",
    string: "Tous droits réservés",
  },
  {
    id: "3",
    string: "Icônes : Flaticon",
  },
];

type CopyrightInfo = {
  id: string;
  string: string;
};

type Props = {
  sections: Array<Section>;
};

function Footer({ sections }: Props): JSX.Element {
  return (
    <div className="flex flex-col">
      <div className="flex flex-col items-center gap-8 py-8 bg-blue-w-full">
        <Logo isVariantBlue={true} />
        <div className="flex flex-col gap-4">
          {sections.map((section: Section) => (
            <a
              key={section.id}
              href={`#${section.id}`}
              className="self-center text-white underline hover:no-underline"
            >
              {section.label}
            </a>
          ))}
        </div>
        <ContactBar />
      </div>
      <div className="flex flex-col gap-2 py-8 text-center bg-white-w-full">
        {COPYRIGHT.map((copyrightInfo: CopyrightInfo) => (
          <span key={copyrightInfo.id} className="text-blue">
            {copyrightInfo.string}
          </span>
        ))}
      </div>
    </div>
  );
}

export default Footer;
