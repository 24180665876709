import { OrderedString } from "../types/OrderedString";
import { Skill } from "../types/Skill";
import SVG from "./SVG";
import {
  NewProjectRequiredSkillsByCategory,
  PROJECT_LABELS,
} from "./ProjectContent";
import clsx from "clsx";

type Props = {
  isPersonalProject: boolean;
  headerSrc: string;
  headerLabel: string;
  itemsElements: Array<NewProjectRequiredSkillsByCategory | OrderedString>;
  isDisplayableOnTwoColumns?: boolean;
};

function ProjectContentSection({
  isPersonalProject,
  headerSrc,
  headerLabel,
  itemsElements,
  isDisplayableOnTwoColumns,
}: Props): JSX.Element {
  const RequiredSkillsSection = (): JSX.Element => {
    const sortedItemsElements = (
      itemsElements as Array<NewProjectRequiredSkillsByCategory>
    ).sort(
      (
        item1: NewProjectRequiredSkillsByCategory,
        item2: NewProjectRequiredSkillsByCategory,
      ) =>
        (item1.skillCategory?.order || 0) - (item2.skillCategory?.order || 0),
    );

    return (
      <>
        {sortedItemsElements.map(
          (
            newProjectRequiredSkillsByCategory: NewProjectRequiredSkillsByCategory,
            index: number,
          ) => (
            <span
              key={
                newProjectRequiredSkillsByCategory.skillCategory
                  ?.skillCategoryId || index
              }
              className={clsx(
                `before-underscore ${isPersonalProject ? "underscore-white" : "underscore-blue"}`,
                {
                  "after-semicolon": index < sortedItemsElements.length - 1,
                  "after-dot": index === sortedItemsElements.length - 1,
                },
              )}
            >
              {
                newProjectRequiredSkillsByCategory.skillCategory
                  ?.skillCategoryLabel
              }{" "}
              :{" "}
              {(
                newProjectRequiredSkillsByCategory.skills
                  .sort(
                    (item1: Skill | undefined, item2: Skill | undefined) =>
                      (item1?.orderByCategoryId || 0) -
                      (item2?.orderByCategoryId || 0),
                  )
                  .map((skill: Skill | undefined) => skill?.skillLabel) || []
              ).join(", ")}
            </span>
          ),
        )}
      </>
    );
  };

  const OtherSection = (): JSX.Element => {
    const sortedItemsElements = (itemsElements as Array<OrderedString>).sort(
      (item1: OrderedString, item2: OrderedString) => item1.order - item2.order,
    );

    return (
      <>
        {sortedItemsElements.map((item: OrderedString, index: number) => (
          <span
            key={item.order}
            className={clsx(
              `before-underscore ${isPersonalProject ? "underscore-white" : "underscore-blue"}`,
              {
                "after-semicolon": index < sortedItemsElements.length - 1,
                "after-dot": index === sortedItemsElements.length - 1,
              },
            )}
          >
            {item.string}
          </span>
        ))}
      </>
    );
  };

  return (
    <div className="flex flex-1 flex-col gap-8">
      <div className="flex items-center gap-4">
        <SVG
          src={headerSrc}
          dimensions="w-12 h-12"
          fillColor={isPersonalProject ? "fill-white" : "fill-blue"}
        />
        <span
          className={`font-bold text-xl md:text-2xl ${isPersonalProject ? "text-white" : "text-blue"}`}
        >
          {headerLabel}
        </span>
      </div>
      <div
        className={clsx("grid gap-4", {
          "lg:grid-cols-2": isDisplayableOnTwoColumns,
        })}
      >
        {headerLabel === PROJECT_LABELS.REQUIRED_SKILLS ? (
          <RequiredSkillsSection />
        ) : (
          <OtherSection />
        )}
      </div>
    </div>
  );
}

export default ProjectContentSection;
