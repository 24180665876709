import { ReactNode } from "react";

type Props = {
  children: ReactNode;
  onClick: () => void;
};

function Button({ children, onClick }: Props): JSX.Element {
  return (
    <button
      onClick={onClick}
      className="rounded-md bg-blue px-4 py-2 font-bold text-white underline hover:no-underline"
    >
      {children}
    </button>
  );
}

export default Button;
