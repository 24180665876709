import { SkillCategory as SkillCategoryType } from "../types/SkillCategory";
import SectionLabel from "./SectionLabel";
import SkillCategory from "./SkillCategory";

type Props = {
  sectionId: string;
  sectionLabel: string;
  skillCategoriesToDisplayInSkills: Array<SkillCategoryType>;
};

function SectionSkills({
  sectionId,
  sectionLabel,
  skillCategoriesToDisplayInSkills,
}: Props): JSX.Element {
  return (
    <div className="flex flex-col items-center gap-8 py-8" id={sectionId}>
      <SectionLabel label={sectionLabel} />
      <div className="flex flex-wrap gap-8">
        {skillCategoriesToDisplayInSkills
          .sort(
            (
              skillCategory1: SkillCategoryType,
              skillCategory2: SkillCategoryType,
            ) => skillCategory1.order - skillCategory2.order,
          )
          .map((skillCategory: SkillCategoryType) => (
            <SkillCategory
              key={skillCategory.skillCategoryId}
              skillCategory={skillCategory}
              skillCategoriesToDisplayInSkills={
                skillCategoriesToDisplayInSkills
              }
            />
          ))}
      </div>
    </div>
  );
}

export default SectionSkills;
