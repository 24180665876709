import useAppStore from "../stores/App";
import { SkillCategory as SkillCategoryType } from "../types/SkillCategory";
import { Skill as SkillType } from "../types/Skill";
import Skill from "./Skill";

type Props = {
  skillCategory: SkillCategoryType;
  skillCategoriesToDisplayInSkills: Array<SkillCategoryType>;
};

function SkillCategory({
  skillCategory,
  skillCategoriesToDisplayInSkills,
}: Props): JSX.Element {
  const skills = useAppStore((state) => state.skills);

  const categoryLabelByCategoryId = (categoryId: string): string => {
    return (
      skillCategoriesToDisplayInSkills.find(
        (category: SkillCategoryType) =>
          category.skillCategoryId === categoryId,
      )?.skillCategoryLabel || ""
    );
  };

  const displayedSkillsByCategoryId = (
    categoryId: string,
  ): Array<SkillType> => {
    return skills.filter(
      (skill: SkillType) =>
        skill.skillCategoryId === categoryId && skill.isDisplayedInSkills,
    );
  };

  return (
    <div className="flex basis-full flex-col gap-8 rounded-lg bg-white px-4 py-8 shadow-lg md:basis-[calc(50%-1.25rem)]">
      <span className="text-center font-bold text-xl text-blue md:text-2xl">
        {categoryLabelByCategoryId(skillCategory.skillCategoryId)}
      </span>
      <div className="grid grid-cols-2 gap-4 xs:max-md:grid-cols-3 md:max-lg:grid-cols-2 lg:grid-cols-3">
        {displayedSkillsByCategoryId(skillCategory.skillCategoryId)
          .sort(
            (skill1: SkillType, skill2: SkillType) =>
              skill1.orderByCategoryId - skill2.orderByCategoryId,
          )
          .map((skill: SkillType) => (
            <Skill key={skill.skillId} skill={skill} />
          ))}
      </div>
    </div>
  );
}

export default SkillCategory;
