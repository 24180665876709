import clsx from "clsx";
import { ReactSVG } from "react-svg";

type Props = {
  src: string;
  dimensions: string;
  areDimensionsOnSvgContainer?: boolean;
  bgColor?: string;
  fillColor?: string;
  addClasses?: string;
};

function SVG({
  src,
  dimensions,
  areDimensionsOnSvgContainer,
  bgColor,
  fillColor,
  addClasses,
}: Props): JSX.Element {
  return (
    <div
      className={clsx("group", {
        [`${dimensions}`]: areDimensionsOnSvgContainer,
        [`${bgColor}`]: bgColor,
        [`${addClasses}`]: addClasses,
      })}
    >
      <ReactSVG
        src={src}
        beforeInjection={(svg: SVGSVGElement) => {
          if (!areDimensionsOnSvgContainer) {
            svg.setAttribute("class", dimensions);
          }

          if (fillColor) {
            const paths = svg.querySelectorAll("path");
            paths.forEach((path: SVGPathElement) =>
              path.setAttribute("class", fillColor),
            );
          }
        }}
      />
    </div>
  );
}

export default SVG;
