import { Project as ProjectType } from "../types/Project";
import ProjectHeader from "./ProjectHeader";
import ProjectContent from "./ProjectContent";

type Props = {
  project: ProjectType;
};

function Project({ project }: Props): JSX.Element {
  return (
    <div
      className={`flex flex-col gap-8 ${project.personal ? "px-4 text-white md:px-8" : "scroll-mt-[calc(6rem+0.625rem)] rounded-lg bg-white px-4 py-8 shadow md:px-8 md:py-16"}`}
      id={project.href}
    >
      <ProjectHeader project={project} />
      <ProjectContent project={project} />
    </div>
  );
}

export default Project;
