import Button from "./Button";

const MESSAGE = "Echec du chargement des données...";

function LoadingFailed(): JSX.Element {
  const reloadPage = (): void => {
    window.location.reload();
  };

  return (
    <div className="flex h-screen flex-col items-center justify-center gap-8">
      <span className="text-center text-blue">{MESSAGE}</span>
      <Button onClick={reloadPage}>Recharger</Button>
    </div>
  );
}

export default LoadingFailed;
