import SVG from "./SVG";
import Location from "../assets/icons/InfoLinks/Location.svg";
import Phone from "../assets/icons/InfoLinks/Phone.svg";
import Email from "../assets/icons/InfoLinks/Email.svg";
import LinkedIn from "../assets/icons/InfoLinks/LinkedIn.svg";

const INFO_LINKS = [
  {
    id: "1",
    src: Location,
    label: "57400 SARREBOURG",
    href: "https://maps.app.goo.gl/zmicLqfFrKCsMJjd6",
  },
  {
    id: "2",
    src: Phone,
    label: "+33 7 60 11 08 01",
    href: "tel:+33760110801",
  },
  {
    id: "3",
    src: Email,
    label: "damien.procaccino@live.fr",
    href: "mailto:damien.procaccino@live.fr",
  },
  {
    id: "4",
    src: LinkedIn,
    label: "Damien PROCACCINO",
    href: "https://www.LinkedIn.com/in/damien-procaccino",
  },
];

type InfoLink = {
  id: string;
  src: string;
  label: string;
  href: string;
};

function ContactBar(): JSX.Element {
  return (
    <div className="flex justify-center gap-4">
      {INFO_LINKS.map((infoLink: InfoLink) => (
        <a key={infoLink.id} href={infoLink.href} aria-label={infoLink.label}>
          <SVG
            src={infoLink.src}
            dimensions="w-12 h-12"
            areDimensionsOnSvgContainer={true}
            bgColor="bg-white hover:bg-blue"
            fillColor="fill-black group-hover:fill-white"
            addClasses="rounded transition duration-300"
          />
        </a>
      ))}
    </div>
  );
}

export default ContactBar;
