import clsx from "clsx";
import Dates from "../assets/icons/Project/Dates.svg";
import CompagnyLocation from "../assets/icons/Project/CompagnyLocation.svg";
import { Project } from "../types/Project";
import SVG from "./SVG";

const translatedDate = (YYYYMMDate: string): string => {
  const split = YYYYMMDate.split("-");
  const YYYY = split[0];
  const date = new Date(split[1]);
  date.setMonth(parseInt(split[1]) - 1);
  const translatedMonth = new Intl.DateTimeFormat("fr-FR", {
    month: "long",
  }).format(date);

  return `${translatedMonth} ${YYYY}`;
};

const dateTo = (YYYYMMDate: string): string => {
  if (YYYYMMDate !== "0") {
    return YYYYMMDate;
  }

  const now = new Date();
  const YYYY = now.getFullYear();
  let month = (now.getMonth() + 1).toString();

  if (month.length < 2) {
    month = `0${month}`;
  }

  return `${YYYY}-${month}`;
};

type Props = {
  project: Project;
};

function ProjectHeader({ project }: Props): JSX.Element {
  return (
    <div className="flex gap-4 border-b border-b-violet pb-8 max-lg:flex-col">
      <div className="flex flex-1 flex-col gap-4">
        {project.personal && (
          <span className="font-bold text-xl text-white md:text-2xl">
            {project.personal.name}
          </span>
        )}
        {project.professional && (
          <span className="font-bold text-xl text-blue md:text-2xl">
            {project.professional.job}
          </span>
        )}
        <span className={clsx({ "text-blue": !project.personal })}>
          {project.subject}
        </span>
      </div>
      <div className="flex flex-1 flex-col gap-4 lg:items-end lg:justify-end">
        <div className="flex items-center gap-4 font-bold">
          <SVG
            src={Dates}
            dimensions="w-6 h-6"
            fillColor={project.personal ? "fill-white" : "fill-black"}
          />
          <span className="capitalize">
            {translatedDate(project.dateFrom)} -{" "}
            {translatedDate(dateTo(project.dateTo))}
          </span>
        </div>
        {project.professional && (
          <div className="flex items-center gap-4 font-bold">
            <SVG
              src={CompagnyLocation}
              dimensions="w-6 h-6"
              fillColor="fill-black"
            />
            <span>
              {project.professional.compagny} - {project.professional.location}
            </span>
          </div>
        )}
      </div>
    </div>
  );
}

export default ProjectHeader;
