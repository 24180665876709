import Logo from "./Logo";
import Photo from "./Photo";
import ContactBar from "./ContactBar";

type Props = {
  experienceYearsNumber: number;
};

function Header({ experienceYearsNumber }: Props): JSX.Element {
  const TEXTS = {
    NAME: "Damien PROCACCINO",
    JOB: "Développeur Front-End Senior",
    EXP: `Bac +5 / ${experienceYearsNumber} ans d'expérience`,
    DESC: "Guidé par les bonnes pratiques de développement, l'optimisation des interfaces et l'expérience utilisateur, je suis toujours en quête de projets, par équipe ou en autonomie, pour approfondir mon expertise sur la partie Front-End des applications",
  } as const;

  return (
    <div className="flex flex-col items-center gap-8 py-8 text-center">
      <Logo />
      <span className="font-bold text-3xl leading-[2.875rem] text-blue md:text-4xl">
        {TEXTS.NAME}
      </span>
      <span className="font-bold text-2xl leading-[2.5rem] md:text-3xl">
        {TEXTS.JOB}
      </span>
      <span className="text-xl md:text-2xl">{TEXTS.EXP}</span>
      <Photo />
      <p className="m-0 max-w-screen-sm">{TEXTS.DESC}</p>
      <ContactBar />
    </div>
  );
}

export default Header;
