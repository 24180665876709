import { Project as ProjectType } from "../types/Project";
import ProjectsNav from "./ProjectsNav";
import Project from "./Project";
import SectionLabel from "./SectionLabel";
import clsx from "clsx";

type Props = {
  sectionId: string;
  sectionLabel: string;
  projects: Array<ProjectType>;
  arePersonalProjects?: boolean;
};

function SectionProjects({
  sectionId,
  sectionLabel,
  projects,
  arePersonalProjects,
}: Props): JSX.Element {
  return (
    <div
      className={clsx("flex flex-col gap-8 py-8", {
        "bg-blue-w-full": arePersonalProjects,
      })}
      id={sectionId}
    >
      <SectionLabel label={sectionLabel} isWhite={arePersonalProjects} />
      {projects.length > 1 && (
        <ProjectsNav
          projects={projects}
          arePersonalProjects={arePersonalProjects}
        />
      )}
      <div className="flex flex-col gap-8">
        {projects
          .sort((project1: ProjectType, project2: ProjectType) =>
            project2.dateFrom.localeCompare(project1.dateFrom),
          )
          .map((project: ProjectType) => (
            <Project key={project.id} project={project} />
          ))}
      </div>
    </div>
  );
}

export default SectionProjects;
