import { Skill as SkillType } from "../types/Skill";

type Props = {
  skill: SkillType;
};

function Skill({ skill }: Props): JSX.Element {
  return (
    <div className="flex flex-col items-center gap-4">
      {skill.src && (
        <img src={skill.src} alt={skill.skillLabel} className="h-12 w-12" />
      )}
      <span className="text-sm">{skill.skillLabel}</span>
    </div>
  );
}

export default Skill;
