type Props = {
  label: string;
  isWhite?: boolean;
};

function SectionLabel({ label, isWhite }: Props): JSX.Element {
  return (
    <span
      className={`text-center font-bold text-2xl md:text-3xl ${isWhite ? "text-white" : "text-blue"}`}
    >
      {label}
    </span>
  );
}

export default SectionLabel;
