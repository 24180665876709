import { Project as ProjectType } from "../types/Project";
import { ProjectRequiredSkillsByCategory } from "../types/ProjectRequiredSkillsByCategory";
import RequiredSkills from "../assets/icons/Project/RequiredSkills.svg";
import Before from "../assets/icons/Project/Before.svg";
import Features from "../assets/icons/Project/Features.svg";
import Around from "../assets/icons/Project/Around.svg";
import Achievments from "../assets/icons/Project/Achievments.svg";
import useAppStore from "../stores/App";
import { SkillCategory } from "../types/SkillCategory";
import { Skill } from "../types/Skill";
import ProjectContentSection from "./ProjectContentSection";

export const PROJECT_LABELS = {
  REQUIRED_SKILLS: "Compétences requises",
  BEFORE: "Prérequis",
  FEATURES: "Fonctionnalités",
  AROUND: "Cadre de travail",
  ACHIEVMENTS: "Réalisations",
} as const;

export type NewProjectRequiredSkillsByCategory = {
  skillCategory: SkillCategory | undefined;
  skills: Array<Skill | undefined>;
};

type Props = {
  project: ProjectType;
};

function ProjectContent({ project }: Props): JSX.Element {
  const { skillCategoryBySkillCategoryId, skillBySkillId } = useAppStore(
    (state) => ({
      skillCategoryBySkillCategoryId: state.skillCategoryBySkillCategoryId,
      skillBySkillId: state.skillBySkillId,
    }),
  );

  const projectRequiredSkills = project.projectRequiredSkills.reduce(
    (
      acc: Array<NewProjectRequiredSkillsByCategory>,
      current: ProjectRequiredSkillsByCategory,
    ) => {
      acc.push({
        skillCategory: skillCategoryBySkillCategoryId(current.skillCategoryId),
        skills: current.skillIds.map((skillId: string) =>
          skillBySkillId(skillId),
        ),
      });

      return acc;
    },
    [],
  );

  return (
    <div className="flex flex-col gap-8">
      <div className="flex gap-8 max-lg:flex-col lg:gap-4">
        <ProjectContentSection
          isPersonalProject={project.personal !== undefined}
          headerSrc={RequiredSkills}
          headerLabel={PROJECT_LABELS.REQUIRED_SKILLS}
          itemsElements={projectRequiredSkills}
        />
        <ProjectContentSection
          isPersonalProject={project.personal !== undefined}
          headerSrc={Before}
          headerLabel={PROJECT_LABELS.BEFORE}
          itemsElements={project.before}
        />
      </div>
      {project.personal && (
        <ProjectContentSection
          isPersonalProject={project.personal !== undefined}
          headerSrc={Features}
          headerLabel={PROJECT_LABELS.FEATURES}
          itemsElements={project.personal.features}
          isDisplayableOnTwoColumns={true}
        />
      )}
      {project.professional && (
        <ProjectContentSection
          isPersonalProject={project.personal !== undefined}
          headerSrc={Around}
          headerLabel={PROJECT_LABELS.AROUND}
          itemsElements={project.professional.around}
          isDisplayableOnTwoColumns={true}
        />
      )}
      <ProjectContentSection
        isPersonalProject={project.personal !== undefined}
        headerSrc={Achievments}
        headerLabel={PROJECT_LABELS.ACHIEVMENTS}
        itemsElements={project.achievments}
        isDisplayableOnTwoColumns={true}
      />
    </div>
  );
}

export default ProjectContent;
