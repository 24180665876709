import { useState, useEffect } from "react";
import { Project } from "../types/Project";
import { SECTIONS } from "../App";
import clsx from "clsx";

type Props = {
  projects: Array<Project>;
  arePersonalProjects?: boolean;
};

function ProjectsNav({ projects, arePersonalProjects }: Props): JSX.Element {
  const [activeProjectId, setActiveProjectId] = useState<string>("");

  useEffect(() => {
    const projectElementsObserver = new IntersectionObserver(
      (projectElements: Array<IntersectionObserverEntry>) => {
        projectElements.forEach((projectElement: IntersectionObserverEntry) => {
          if (projectElement.isIntersecting) {
            setActiveProjectId(projectElement.target.id);
          }
        });
      },
      {
        root: null,
        rootMargin: "-50%",
        threshold: 0,
      },
    );

    /**
     *  rootMargin: '-50%' and threshold: 0 means that the intersection will be detected when
     *  the first part of the projectElement (i.e. top or bottom edge), so a threshold value of 0, intersects with
     *  the observation area modified by the rootMargin, here the middle of the page, so a rootMargin value of -50%
     */

    const projectElements =
      document
        .querySelector(
          `#${arePersonalProjects ? SECTIONS.PERSONAL_PROJECTS.id : SECTIONS.PROFESSIONAL_PROJECTS.id}`,
        )
        ?.querySelectorAll("[id]") || [];

    projectElements.forEach((projectElement: Element) =>
      projectElementsObserver.observe(projectElement),
    );

    return () => {
      projectElements.forEach((projectElement: Element) =>
        projectElementsObserver.unobserve(projectElement),
      );
    };
  }, [arePersonalProjects]);

  return (
    <nav className="sticky top-0 flex h-24 items-center justify-evenly bg-violet-w-full">
      {projects
        .sort((project1: Project, project2: Project) =>
          project2.dateFrom.localeCompare(project1.dateFrom),
        )
        .map((project: Project) => (
          <a
            key={project.id}
            title={`${project.personal?.name || project.professional?.job} - ${project.subject}`}
            href={`#${project.href}`}
            className={clsx(
              "font-bold underline transition-transform duration-300 max-lg:before-xp lg:before-experience",
              {
                "scale-150 text-blue": activeProjectId === project.href,
              },
            )}
          >
            <span className="max-lg:align-sub">{project.num}</span>
          </a>
        ))}
    </nav>
  );
}

export default ProjectsNav;
