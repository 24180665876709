import SVG from "./SVG";
import LogoVariantWhite from "../assets/icons/LogoVariantWhite.svg";
import LogoVariantBlue from "../assets/icons/LogoVariantBlue.svg";

type Props = {
  isVariantBlue?: boolean;
};

function Logo({ isVariantBlue }: Props): JSX.Element {
  return (
    <SVG
      src={isVariantBlue ? LogoVariantBlue : LogoVariantWhite}
      dimensions="w-16 h-16"
      areDimensionsOnSvgContainer={true}
      bgColor={isVariantBlue ? "bg-white" : "bg-blue"}
      addClasses="p-4 rounded-xl content-center"
    />
  );
}

export default Logo;
