const MESSAGE = "Chargement des données en cours";

const NB_POINTS = 3;

function LoadingInProgress(): JSX.Element {
  return (
    <div className="flex h-screen flex-col items-center justify-center gap-8">
      <span className="text-center text-blue">{MESSAGE}</span>
      <div className="flex w-16 justify-between">
        {[...Array(NB_POINTS)].map((_, index: number) => (
          <div
            key={index}
            className="h-4 w-4 animate-growShrink rounded-full bg-blue"
            style={{
              animationDelay: `${index * 300}ms`,
            }}
          />
        ))}
      </div>
    </div>
  );
}

export default LoadingInProgress;
